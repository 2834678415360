import mirrorCreator from 'mirror-creator'
import { createActions, handleActions } from 'redux-actions'

const types = mirrorCreator([
  'FETCH_PRICES_REQUEST',
  'FETCH_PRICES_SUCCESS',
  'FETCH_PRICES_FAILED',

  'NEW_MBWAYPAY_REQUEST',
  'NEW_MBWAYPAY_SUCCESS',
  'NEW_MBWAYPAY_FAILED',

  'NEW_MBPAY_REQUEST',
  'NEW_MBPAY_SUCCESS',
  'NEW_MBPAY_FAILED'
])

const creators = createActions(
  types.FETCH_PRICES_REQUEST,
  types.FETCH_PRICES_SUCCESS,
  types.FETCH_PRICES_FAILED,

  types.NEW_MBWAYPAY_REQUEST,
  types.NEW_MBWAYPAY_SUCCESS,
  types.NEW_MBWAYPAY_FAILED,

  types.NEW_MBPAY_REQUEST,
  types.NEW_MBPAY_SUCCESS,
  types.NEW_MBPAY_FAILED
)

export const initialState = {
  loading: false,
  success: false,
  failure: false,

  prices: [],
  mbWayPayment: undefined,
  mbPayment: undefined,
  error: null
}

export const reducer = handleActions(
  {
    [types.FETCH_PRICES_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.FETCH_PRICES_SUCCESS]: (state, action) => {
      const { payload } = action

      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false,

        prices: payload.prices
      })
    },
    [types.FETCH_PRICES_FAILED]: (state, action) => {
      const { payload } = action

      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true,

        error: payload
      })
    },

    [types.NEW_MBWAYPAY_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.NEW_MBWAYPAY_SUCCESS]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false,

        mbWayPayment: payload
      })
    },
    [types.NEW_MBWAYPAY_FAILED]: (state) => {
      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true
      })
    },

    [types.NEW_MBPAY_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.NEW_MBPAY_SUCCESS]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false,

        mbPayment: payload
      })
    },
    [types.NEW_MBPAY_FAILED]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true,

        error: payload.status
      })
    }
  },
  initialState
)

export const payTypes = types

export default creators
