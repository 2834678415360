import { takeLatest, call, put, fork, delay, select } from 'redux-saga/effects'
import actions, { signinTypes } from '../redux'

function * login ({ api }, { payload }) {
  const { response, error, status } = yield call(api.login, payload)
  if (response) {
    yield put(actions.newLoginSuccess())
  } else {
    yield put(actions.newLoginFailed(status))
    yield delay(1000)
  }
}

// WATCHER
function * watchSetLogin (services) {
  yield takeLatest(signinTypes.NEW_LOGIN_REQUEST, login, services)
}

export default function * root (services) {
  yield fork(watchSetLogin, services)
}
