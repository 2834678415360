import React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

export function PaymentCategories({ categories, submitCategory }) {
  const [category, setCategory] = React.useState('')
  const [price, setPrice] = React.useState()
  const [period, setPeriod] = React.useState(0)
  const { t } = useTranslation()
  const handleChange = (event) => {
    setCategory(event.target.value)
  }
  const style = {
    background: 'linear-gradient(45deg,#8984f0  30%, #8984f0  90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    marginLeft: 10,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
  }

  const handlePriceChange = (e) => {
    const regex = /[+-]?\d+(\.\d+)?/g
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPrice(e.target.value.split(' ').join(''))
    }
  }

  const handlePeriodChange = (e) => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPeriod(e.target.value.split(' ').join(''))
    }
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, width: '25ch' }} size='medium'>
        <InputLabel id='demo-simple-select-label'>
          {t('paymentCategories.memberCategory')}
        </InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={category}
          label='memberCategory'
          onChange={handleChange}
        >
          {
          categories?.map((name, index) => {
            return <MenuItem key={index} value={name}>{name}</MenuItem>
          })
          }
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: '25ch' }} size='medium'>
        <TextField
          id='outlined-basic'
          label={t('paymentCategories.period')}
          variant='outlined'
          InputProps={{
            endAdornment: <InputAdornment position='start'>{t('paymentCategories.months')}</InputAdornment>
          }}
          onChange={(e) => handlePeriodChange(e)}
          type='number'
        />
      </FormControl>
      <Box
        component='form'
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' }
        }}
        noValidate
        autoComplete='off'
      >
        <TextField
          id='outlined-basic'
          label={t('paymentCategories.value')}
          variant='outlined'
          InputProps={{
            endAdornment: <InputAdornment position='start'>€</InputAdornment>
          }}
          type='number'
          step="0.01"
          onChange={(e) => handlePriceChange(e)}
        />
      </Box>
      <Button type="submit"
          size="medium"
          variant="contained"
          style={style}
          onClick={() => submitCategory(category, parseFloat(price), parseInt(period))}
          >{t('paymentSettings.button')}</Button>
    </Box>
  )
}
