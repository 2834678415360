import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Routes from './Routes.js'
import actions from './redux/globalStateRedux.js'
import { useLocalStorageState } from './utils/utils'

const App = () => {
  const dispatch = useDispatch()
  const [authTokens, setAuthTokens] = useLocalStorageState('authTokens')

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const refreshToken = queryParameters.get('refresh')
    const token = queryParameters.get('token')
    if (!!refreshToken || !!token) {
      setAuthTokens('Bearer ' + token)
    }
  }, [])

  useEffect(() => {
    if (authTokens && authTokens !== 'undefined' && authTokens !== 'null') {
      try {
        dispatch(actions.fetchAccountRequest(authTokens))
      } catch (err) {
      }
    }
  }, [authTokens])

  return (
      <Routes />
  )
}

export default App
