import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import './index.css'
import './lib/i18n'
import createStore from './redux'
import reportWebVitals from './reportWebVitals'

const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Stabilgrotesk, sans-serif'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Stabilgrotesk";
          src: url("fonts/StabilGrotesk-Light.ttf") format("truetype"), url("fonts/StabilGrotesk-Light.woff2") format("woff2");
          font-weight: 300;
          font-style: normal;
          font-display: swap;  
        }

        html, body {
          font-family: "Stabilgrotesk, sans-serif";
        }
      `
    }
  }
})

const store = createStore()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)

reportWebVitals()
