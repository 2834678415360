import mirrorCreator from 'mirror-creator'
import { createActions, handleActions } from 'redux-actions'

const types = mirrorCreator([
  'NEW_LOGIN_REQUEST',
  'NEW_LOGIN_SUCCESS',
  'NEW_LOGIN_FAILED'
])

const creators = createActions(
  types.NEW_LOGIN_REQUEST,
  types.NEW_LOGIN_SUCCESS,
  types.NEW_LOGIN_FAILED
)

export const initialState = {
  loading: false,
  success: false,
  failure: false,

  statusCode: undefined
}

export const reducer = handleActions(
  {
    [types.NEW_LOGIN_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.NEW_LOGIN_SUCCESS]: (state) => {
      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false
      })
    },
    [types.NEW_LOGIN_FAILED]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true,
        statusCode: payload

      })
    }
  },
  initialState
)

export const signinTypes = types

export default creators
