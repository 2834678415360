import React from 'react'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import {
  BlankView,
  DashboardView,
  PaymentView,
  QuotasView,
  RegisterView,
  ScanView,
  SigninView,
  AdminView,
  PaymentSettings
} from './views'

import Layout from './components/Layout'
import ProtectedRoute from './utils/ProtectedRoute'
import { Associates } from './views/associates'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        element: <DashboardView />,
        index: true
      },
      {
        path: '/associates',
        element: <Associates></Associates>
      },
      {
        path: '/associates/account',
        element: <AdminView />
      },
      { path: '/quotas', element: <QuotasView /> },
      { path: '/payment', element: <PaymentView /> },
      { path: '/paymentSettings', element: <PaymentSettings /> }
    ]
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [{ path: '/scan', element: <ScanView /> }]
  },
  {
    path: '/sign-in',
    element: <SigninView />
  },
  {
    path: '/register',
    element: <RegisterView />
  },
  {
    path: '/blank',
    element: <BlankView />
  },
  {
    path: '*',
    element: <Navigate to='/' replace />
  }
])

function Routes() {
  const queryParameters = new URLSearchParams(window.location.search)
  const refreshToken = queryParameters.get('refresh')
  const token = queryParameters.get('token')
  if ((!!refreshToken || !!token) && token) {
    localStorage.setItem(
      'authTokens',
      JSON.stringify('Bearer ' + token)
    )
  }

  return <RouterProvider router={router} />
}

export default Routes
