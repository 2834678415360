import React from 'react'
import TextField from '@mui/material/TextField'

const NumberTextField = ({ setNumber, error, helperText, value, label, required }) => {
  const handleChange = (e) => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setNumber(e.target.value.split(' ').join(''))
    }
  }
  return (
        <TextField
            error= {error}
            helperText= {helperText}
            value={value}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            label={label}
            required={required}
            inputProps={{ style: { textTransform: 'lowercase' } }}
          ></TextField>
  )
}
export default NumberTextField
