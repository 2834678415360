import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function Toast({ status, message }) {
  const [open, setOpen] = React.useState(true)

  if (!message) {
    if (status) {
      message = ' Sucess!!'
    } else {
      message = ' Error !!'
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={status ? 'success' : 'error'}
        sx={{ width: '100%' }}
    >
        {message}
      </Alert>
    </Snackbar>
  )
}
