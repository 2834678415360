import mirrorCreator from 'mirror-creator'
import { createActions, handleActions } from 'redux-actions'

const types = mirrorCreator([
  'FETCH_CATEGORIES_REQUEST',
  'FETCH_CATEGORIES_SUCCESS',
  'FETCH_CATEGORIES_FAILED',
  'CREATE_ACCOUNT_REQUEST',
  'CREATE_ACCOUNT_SUCCESS',
  'CREATE_ACCOUNT_FAILED'
])

const creators = createActions(
  types.FETCH_CATEGORIES_REQUEST,
  types.FETCH_CATEGORIES_SUCCESS,
  types.FETCH_CATEGORIES_FAILED,
  types.CREATE_ACCOUNT_REQUEST,
  types.CREATE_ACCOUNT_SUCCESS,
  types.CREATE_ACCOUNT_FAILED
)

export const initialState = {
  loading: false,
  success: false,
  failure: false,

  availableCategories: [],
  account: null,
  error: null
}

export const reducer = handleActions(
  {
    [types.FETCH_CATEGORIES_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.FETCH_CATEGORIES_SUCCESS]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false,

        availableCategories: payload.availableCategories
      })
    },
    [types.FETCH_CATEGORIES_FAILED]: (state) => {
      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true
      })
    },
    [types.CREATE_ACCOUNT_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.CREATE_ACCOUNT_SUCCESS]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false,

        account: payload
      })
    },
    [types.CREATE_ACCOUNT_FAILED]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true,

        error: payload
      })
    }
  },
  initialState
)

export const registerTypes = types

export default creators
