import React from 'react'
import { Navigate } from 'react-router-dom'
import { useLocalStorageState } from './utils'
import { useSelector } from 'react-redux'
const ProtectedRoute = ({ children }) => {
  const [userToken, setUserToken] = useLocalStorageState('authTokens')
  const failure = useSelector((state) => state.global.failure)
  const status = useSelector((state) => state.global.status)

  if (!userToken || userToken === 'undefined' || userToken === 'null') {
    return <Navigate to="/sign-in"></Navigate>
  }

  if (failure) {
    if (status === 401) {
      return <Navigate to="/sign-in" />
    }
  }

  return (
        <React.Fragment>{children}</React.Fragment>
  )
}
export default ProtectedRoute
