import mirrorCreator from 'mirror-creator'
import { createActions, handleActions } from 'redux-actions'

const types = mirrorCreator([
  'FETCH_QUOTAS_REQUEST',
  'FETCH_QUOTAS_SUCCESS',
  'FETCH_QUOTAS_FAILED'
])

const creators = createActions(
  types.FETCH_QUOTAS_REQUEST,
  types.FETCH_QUOTAS_SUCCESS,
  types.FETCH_QUOTAS_FAILED
)

export const initialState = {
  loading: false,
  success: false,
  failure: false,

  subscriptionHistory: [],
  status: null
}

export const reducer = handleActions(
  {
    [types.FETCH_QUOTAS_REQUEST]: (state) =>
      Object.freeze({
        ...state,
        loading: true,
        success: false,
        failure: false
      }),
    [types.FETCH_QUOTAS_SUCCESS]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: true,
        failure: false,

        subscriptionHistory: payload['subscription-history']
      })
    },
    [types.FETCH_QUOTAS_FAILED]: (state, action) => {
      const { payload } = action
      return Object.freeze({
        ...state,
        loading: false,
        success: false,
        failure: true,

        status: payload
      })
    }
  },
  initialState
)

export const quotasTypes = types

export default creators
